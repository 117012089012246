@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
}

:root {
    /* color */
    --colorPrimary: #0d263b;
    --colorSecondary: #696989;

    /* background */
    --backgroundPrimary: #0861df;

    /* padding */
    --padding: 0 130px;
    --width: 1440px;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
}

.subTitle {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0d263b;
}

.info {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #696969;
}

.inline {
    display: flex;
    overflow: none;
    white-space: nowrap;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
    font-size: 25px !important;
}

.slick-dots-bottom {
    width: inherit !important;
}